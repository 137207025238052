.CollapsibleContent {
  overflow: hidden;
}
/* .CollapsibleContent[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.CollapsibleContent[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
} */

.CollapsibleChevron {
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.CollapsibleTrigger[data-state='open'] > .CollapsibleChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}